.password-wrapper {
  min-width: 0;
  white-space: pre-wrap;
  word-break: break-all;
}

.password-row {
  min-width: 0;
}

.password-letter {
  @include themify($themes) {
    color: themed("pwLetter");
  }
}

.password-number {
  @include themify($themes) {
    color: themed("pwNumber");
  }
}

.password-special {
  @include themify($themes) {
    color: themed("pwSpecial");
  }
}

app-vault-groupings,
app-org-vault-groupings,
.groupings {
  .card {
    #search {
      margin-bottom: 1rem;
      @include themify($themes) {
        background-color: themed("inputBackgroundColor");
        border-color: themed("inputBorderColor");
        color: themed("inputTextColor");
      }

      &::placeholder {
        @include themify($themes) {
          color: themed("inputPlaceholderColor");
        }
      }
    }

    h3 {
      font-weight: normal;
      text-transform: uppercase;
      @include themify($themes) {
        color: themed("textMuted");
      }
    }

    ul:last-child {
      margin-bottom: 0;
    }

    .card-body a {
      @include themify($themes) {
        color: themed("textHeadingColor");
        font-weight: themed("linkWeight");
      }

      &:hover {
        &.text-muted {
          @include themify($themes) {
            color: themed("iconHover") !important;
          }
        }
      }
    }

    .show-active {
      display: none;
    }

    li {
      > .bwi,
      > div > .bwi {
        cursor: pointer;
      }
    }

    li.active {
      > .show-active,
      > div .show-active {
        display: inline;
      }
    }

    li.active {
      > a:first-of-type,
      > div a:first-of-type {
        font-weight: bold;
        @include themify($themes) {
          color: themed("linkColor");
        }
      }

      > .bwi,
      > div > .bwi {
        @include themify($themes) {
          color: themed("linkColor");
        }
      }
    }
  }
}

app-password-generator {
  #lengthRange {
    width: 100%;
  }

  .card-password {
    .card-body {
      @include themify($themes) {
        background: themed("foregroundColor");
      }
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      font-family: $font-family-monospace;
      font-size: $font-size-lg;
      justify-content: center;
      text-align: center;
    }
  }
}

app-password-generator-history {
  .list-group-item {
    line-height: 1;
    @include themify($themes) {
      background: themed("backgroundColor");
    }

    .password {
      font-family: $font-family-monospace;
    }
  }
}

app-import {
  textarea {
    height: 150px;
  }
}

app-avatar {
  img {
    @extend .rounded;
  }
}

app-user-billing {
  .progress {
    height: 20px;

    .progress-bar {
      min-width: 50px;
    }
  }
}

app-sponsored-families {
  .inset-list {
    padding-left: 1.5rem;
  }
}

/* Register Layout Page */
.layout {
  &.default,
  &.teams,
  &.teams1,
  &.teams2,
  &.enterprise,
  &.enterprise1,
  &.enterprise2,
  &.cnetcmpgnent,
  &.cnetcmpgnteams,
  &.cnetcmpgnind {
    header {
      background: #175ddc;
      color: #ced4da;
      height: 70px;

      &:before {
        background: #175ddc;
        content: "";
        height: 520px;
        left: 0;
        position: absolute;
        top: -80px;
        transform: skewY(-3deg);
        width: 100%;
        z-index: -1;
      }
      img.logo {
        height: 57px;
        margin: 12px 0 0;
        max-width: 284px;
        width: 284px;
      }
    }

    h1 {
      color: #ffffff;
      font-size: 3.5rem;
      margin: 50px 0 0;
    }

    h2 {
      color: #ffffff;
      font-size: 2rem;
      line-height: 1.5;
      margin: 20px 0 140px;
    }

    p {
      font-size: 2rem;
      margin: 10px 0 70px 0;

      &:before {
        content: "/";
        padding-right: 12px;
      }
      &:not(.highlight) {
        &:before {
          color: #1252a3;
        }
      }

      b {
        &:after {
          content: "⟶";
          font-size: 2rem;
          padding-left: 6px;
        }
      }
    }

    figure {
      margin: 0;
    }

    blockquote {
      font-size: 1.4rem;
      margin: 20px 0 0;
    }
  }

  &.cnetcmpgnind {
    p {
      font-size: 1.5rem;
      margin: 10px 0 50px 0;
    }
  }
}
