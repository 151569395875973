$icomoon-font-family: "bwi-font" !default;
$icomoon-font-path: "~@bitwarden/jslib-angular/src/scss/bwicons/fonts/" !default;

// New font sheet? Update the font-face information below
@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url($icomoon-font-path + "bwi-font.svg") format("svg"),
    url($icomoon-font-path + "bwi-font.ttf") format("truetype"),
    url($icomoon-font-path + "bwi-font.woff") format("woff"),
    url($icomoon-font-path + "bwi-font.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// Base Class
.bwi {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Fixed Width Icons
.bwi-fw {
  width: (18em / 14);
  text-align: center;
}

// Sizing Changes
.bwi-sm {
  font-size: 0.875em;
}

.bwi-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}

.bwi-2x {
  font-size: 2em;
}

.bwi-3x {
  font-size: 3em;
}

.bwi-4x {
  font-size: 4em;
}

// Spin Animations
.bwi-spin {
  animation: bwi-spin 2s infinite linear;
}

@keyframes bwi-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

// List Icons
.bwi-ul {
  padding-left: 0;
  margin-left: (30em / 14);
  list-style-type: none;
  > li {
    position: relative;
  }
}

.bwi-li {
  position: absolute;
  left: -(30em / 14);
  width: (30em / 14);
  top: (2em / 14);
  text-align: center;
  &.bwi-lg {
    left: -(30em / 14) + (4em / 14);
  }
}

// Rotation
.bwi-rotate-270 {
  transform: rotate(270deg);
}

// For new icons - add their glyph name and value to the map below
$icons: (
  "save-changes": "\e988",
  "browser": "\e985",
  "mobile": "\e986",
  "cli": "\e987",
  "providers": "\e983",
  "vault": "\e984",
  "folder-closed-f": "\e982",
  "rocket": "\e9ee",
  "ellipsis-h": "\e9ef",
  "ellipsis-v": "\e9f0",
  "safari": "\e974",
  "opera": "\e975",
  "firefox": "\e976",
  "edge": "\e977",
  "chrome": "\e978",
  "star-f": "\e979",
  "arrow-circle-up": "\e97a",
  "arrow-circle-right": "\e97b",
  "arrow-circle-left": "\e97c",
  "arrow-circle-down": "\e97d",
  "undo": "\e97e",
  "bolt": "\e97f",
  "puzzle": "\e980",
  "rss": "\e973",
  "dbl-angle-left": "\e970",
  "dbl-angle-right": "\e971",
  "hamburger": "\e972",
  "bw-folder-open-f": "\e93e",
  "desktop": "\e96a",
  "angle-left": "\e96b",
  "user": "\e900",
  "user-f": "\e901",
  "key": "\e902",
  "share-square": "\e903",
  "hashtag": "\e904",
  "clone": "\e905",
  "list-alt": "\e906",
  "id-card": "\e907",
  "credit-card": "\e908",
  "globe": "\e909",
  "sticky-note": "\e90a",
  "folder": "\e90b",
  "lock": "\e90c",
  "lock-f": "\e90d",
  "generate": "\e90e",
  "generate-f": "\e90f",
  "cog": "\e910",
  "cog-f": "\e911",
  "check-circle": "\e912",
  "eye": "\e913",
  "pencil-square": "\e914",
  "bookmark": "\e915",
  "files": "\e916",
  "trash": "\e917",
  "plus": "\e918",
  "star": "\e919",
  "list": "\e91a",
  "angle-right": "\e91b",
  "external-link": "\e91c",
  "refresh": "\e91d",
  "search": "\e91f",
  "filter": "\e920",
  "plus-circle": "\e921",
  "user-circle": "\e922",
  "question-circle": "\e923",
  "cogs": "\e924",
  "minus-circle": "\e925",
  "send": "\e926",
  "send-f": "\e927",
  "download": "\e928",
  "pencil": "\e929",
  "sign-out": "\e92a",
  "share": "\e92b",
  "clock": "\e92c",
  "angle-down": "\e92d",
  "caret-down": "\e92e",
  "square": "\e92f",
  "collection": "\e930",
  "bank": "\e931",
  "shield": "\e932",
  "stop": "\e933",
  "plus-square": "\e934",
  "save": "\e935",
  "sign-in": "\e936",
  "spinner": "\e937",
  "dollar": "\e939",
  "check": "\e93a",
  "check-square": "\e93b",
  "minus-square": "\e93c",
  "close": "\e93d",
  "share-arrow": "\e96c",
  "paperclip": "\e93f",
  "bitcoin": "\e940",
  "cut": "\e941",
  "frown": "\e942",
  "folder-open": "\e943",
  "bug": "\e946",
  "chain-broken": "\e947",
  "dashboard": "\e948",
  "envelope": "\e949",
  "exclamation-circle": "\e94a",
  "exclamation-triangle": "\e94b",
  "caret-right": "\e94c",
  "file-pdf": "\e94e",
  "file-text": "\e94f",
  "info-circle": "\e952",
  "lightbulb": "\e953",
  "link": "\e954",
  "linux": "\e956",
  "long-arrow-right": "\e957",
  "money": "\e958",
  "play": "\e959",
  "reddit": "\e95a",
  "refresh-tab": "\e95b",
  "sitemap": "\e95c",
  "sliders": "\e95d",
  "tag": "\e95e",
  "thumb-tack": "\e95f",
  "thumbs-up": "\e960",
  "unlock": "\e962",
  "users": "\e963",
  "wrench": "\e965",
  "ban": "\e967",
  "camera": "\e968",
  "chevron-up": "\e969",
  "eye-slash": "\e96d",
  "file": "\e96e",
  "paste": "\e96f",
  "github": "\e950",
  "facebook": "\e94d",
  "paypal": "\e938",
  "google": "\e951",
  "linkedin": "\e955",
  "discourse": "\e91e",
  "twitter": "\e961",
  "youtube": "\e966",
  "windows": "\e964",
  "apple": "\e945",
  "android": "\e944",
  "error": "\e981",
);

@each $name, $glyph in $icons {
  .bwi-#{$name}:before {
    content: $glyph;
  }
}

:export {
  @each $name, $glyph in $icons {
    #{$name}: $glyph;
  }
}
