.callout {
  border-left-width: 5px !important;
  border-radius: $card-inner-border-radius;
  margin-bottom: $alert-margin-bottom;
  padding: $alert-padding-y $alert-padding-x;
  @include themify($themes) {
    background-color: themed("calloutBackground");
    border: 1px solid themed("borderColor");
    color: themed("calloutColor");
  }

  .callout-heading {
    margin-top: 0;
  }

  h3.callout-heading {
    font-weight: bold;
    text-transform: uppercase;
  }

  &.callout-primary {
    @include themify($themes) {
      border-left-color: themed("primary");
    }
    .callout-heading {
      @include themify($themes) {
        color: themed("primary");
      }
    }
  }

  &.callout-info {
    @include themify($themes) {
      border-left-color: themed("info");
    }

    .callout-heading {
      @include themify($themes) {
        color: themed("info");
      }
    }
  }

  &.callout-danger {
    @include themify($themes) {
      border-left-color: themed("danger");
    }

    .callout-heading {
      @include themify($themes) {
        color: themed("danger");
      }
    }
  }

  &.callout-success {
    @include themify($themes) {
      border-left-color: themed("success");
    }

    .callout-heading {
      @include themify($themes) {
        color: themed("success");
      }
    }
  }

  &.callout-warning {
    @include themify($themes) {
      border-left-color: themed("warning");
    }

    .callout-heading {
      @include themify($themes) {
        color: themed("warning");
      }
    }
  }

  .enforced-policy-options ul {
    margin-bottom: 0px;
  }
}
